import React from 'react'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { VideoFrame } from '../../components/VideoFrame'
import { PageTitle } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import SameActivity from '../images/sameActivityMoreData.png'
import TeamDashLaptop from '../images/teamDashOnLaptop.svg'
import Security from '../images/securityShield.svg'

const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Sales Enablement</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene1'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
          JUST-IN-TIME LEARNING AND TRACKABLE COACHING
          </Title3>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
          SCALE THE IMPACT OF YOUR ENABLEMENT EFFORTS
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
      <Box width={[1, 2/3]}>
        <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
        ACCELERATE ONBOARDING
        </Title3>
        <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
        With easily searchable call recordings and notes, your team can source content quickly to build onboarding programs that scale.
        </Large>
      </Box>
      <Box width={[2/3, 1/2]}>
          <WidthImg src={SameActivity} alt="Recordings"/>
      </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={TeamDashLaptop} alt="Laptop Dashboard"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          SCALE LIVE COACHING
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
          Truly's call monitor dashboard shows what’s happening on your sales floor in realtime. Identify the conversations that need the most attention and provide live feedback using the whisper and barge features.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground
      gradientType='light'
      background='serverRoom'
    >
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <CenteredImg src={Security} alt="Security"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          PROTECT YOUR CUSTOMER'S DATA
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Truly is the only platform that provides government grade compliance to ensure you legally capture data from your conversations.

          </Large>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperBackground
      color={colors.trulyDark}
      background='managedByQ'
      gradientType='dark'
    >
      <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 1/2]}>
          <Large as="p" color={colors.white} my="1.5rem">
            "We have reps in local markets all over the country, which obviously makes it difficult to coach them from a managerial perspective. The Call Monitor feature allows us to tune right into a call and be able to provide feedback right then and there"
          </Large>
          <VideoFrame background="sales" videoLink="https://player.vimeo.com/video/268683508?autoplay=1" />
        </Box>
      </Section>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage
