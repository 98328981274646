import styled from 'styled-components'

export const CenteredImg = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
`

export const WidthImg = styled.img`
  display: block;
  max-width: 100%;
`